import React, { useContext } from 'react';
import UserContext from "contexts/UserContext";
import { Dialog, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import DateTimeSelector from "components/DateTimeSelector";
import { XMarkIcon } from '@heroicons/react/20/solid';

const AddDateModal = React.memo(({ open, handleClose, handleSave, startDateTime, endDateTime, setStartDateTime, setEndDateTime }) => {
const { eventSettings } = useContext(UserContext);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="add-date-modal-title" className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 hidden pr-5 pt-5 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <DialogTitle id="add-date-modal-title" className="text-base font-semibold leading-6 text-gray-900">
              Add Start & End Date
            </DialogTitle>
            <DialogContent>
						<p className="text-sm text-gray-500 mb-4">
								This controls which calendar days appear on the{' '}
								<a
									href={`https://${eventSettings?.subdomain}`}
									target="_blank"
									rel="noopener noreferrer"
									className="text-indigo-600 underline hover:text-indigo-500"
								>
									Guest schedule site
								</a>.
							</p>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-900 pb-1.5">Start Date/Time:</label>
                <DateTimeSelector dateTime={startDateTime} setDateTime={setStartDateTime} timezone={eventSettings?.timezone} />
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-900 pb-1.5">End Date/Time:</label>
                <DateTimeSelector dateTime={endDateTime} setDateTime={setEndDateTime} timezone={eventSettings?.timezone} />
              </div>
            </DialogContent>

            <DialogActions>
              <button
                onClick={handleClose}
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none ml-3 disabled:opacity-25"
                disabled={!startDateTime || !endDateTime}
              >
                Save
              </button>
            </DialogActions>
          </div>
        </div>
      </div>
    </Dialog>
  );
});

export default AddDateModal;
