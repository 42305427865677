import React, { useContext, useState, useCallback, useEffect } from "react";
import UserContext from "contexts/UserContext";
import { formatDateWithoutDay } from "utils/formatter";
import { formatDateWithoutYear } from "utils/formatter";
import { CalendarIcon, ComputerDesktopIcon } from "@heroicons/react/20/solid";
import AddDateModal from "components/AddDateModal";
import useEvents from "hooks/useEvents";
import { useSnackbar } from "notistack";
import moment from "moment-timezone";

export default function EventRecord() {
  const { enqueueSnackbar } = useSnackbar();
  const { eventTimezone, eventSettings } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const eventHooks = useEvents();
  const {
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    fetchEventSettings,
    onUpdate,
  } = eventHooks;

  useEffect(() => {
    if (eventSettings) {
      fetchEventSettings(eventSettings.id);
      setIsLoading(false);
    }
  }, [eventSettings]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    
    if (eventSettings?.id) {
      fetchEventSettings(eventSettings.id);
    }
  }, [eventSettings, fetchEventSettings]);


  const handleSave = useCallback(() => {
    if (moment.utc(endDateTime).isSameOrBefore(moment.utc(startDateTime))) {
      enqueueSnackbar("End date must be after the start date", {
        variant: "error",
      });
      return;
    }

    onUpdate();
    handleClose();
  }, [startDateTime, endDateTime, onUpdate, enqueueSnackbar, handleClose]);

  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap gap-x-4">
          {eventSettings?.subdomain && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ComputerDesktopIcon className="mr-1.5 size-5 shrink-0 text-gray-400" />
              <a
                href={`https://${eventSettings.subdomain}`}
                className="text-blue-600 hover:underline"
                target="_blank"
              >
                {`https://${eventSettings.subdomain}`}
              </a>
            </div>
          )}
          {isLoading ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              Loading...
            </div>
          ) : (
            <>
              {startDateTime && endDateTime ? (
                <>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="mr-1.5 size-5 shrink-0 text-gray-400" /><button
                    onClick={() => setIsModalOpen(true)} className="text-blue-600 hover:underline"
                    >{formatDateWithoutYear(startDateTime, eventTimezone)}&nbsp;-&nbsp;
                    {formatDateWithoutDay(endDateTime, eventTimezone)}</button>
                  </div>
                </>
              ) : (
                <>
                {(!eventSettings?.startDatetimeUtc || !eventSettings?.endDatetimeUtc) && (
                <>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="mr-1.5 size-5 shrink-0 text-gray-400" /> <button
                    onClick={() => setIsModalOpen(true)}
                    className="text-blue-600 hover:underline"
                  >
                    Set start & end dates
                  </button>
                </div>
                </>
                )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <AddDateModal
        open={isModalOpen}
        handleClose={handleClose}
        handleSave={handleSave}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        setStartDateTime={setStartDateTime}
        setEndDateTime={setEndDateTime}
      />
    </div>
  );
}
